.Footer {
	background-color: rgb(14, 17, 26);
	color: white;
	padding-top: 3rem;
  margin-top: auto;
	position: relative;

	.topLine {
		background-color: rgb(25, 28, 37);
		height: 1px;
		width: 100%;
		margin-bottom: 1rem;
	}
	.title {
		font-size: 18px;
		margin-bottom: 5px;
		position: relative;
		&::after {
			content: ' ';
			display: block;
			position: absolute bottom;
			height: 2px;
			background: rgb(25, 28, 37);
			width: 25px;
			left: 100%;
			top: calc(50% - 2px);
		}
	}
	.content {
		font-size: 14px;
		flex-direction: row;
		color: #798795;

		a {
			color: inherit;
			text-decoration: none;
		}

		.content-element {
			margin-top: 5px;
			margin-bottom: 5px;

			transition: 0.25s ease-in-out;
			&:hover {
				color: #f7711c;
				cursor: pointer;
			}
		}
	}
	.menu {
		display: flex;
		margin-top: 10px;
		.infoPart {
			.link {
				font-size: 16px;
				vertical-align: middle;
				color: #c2cef3;

				transition: 0.25s ease-in-out;

				&:hover {
					color: #f7711c;
					cursor: pointer;
				}
				@media (max-width: 991px) {
					font-size: 13px;
				}
				@media (max-width: 480px) {
					font-size: 1rem;
				}
			}
		}
		.infoIcon {
			justify-content: space-evenly;
			.littleIcons {
				background-color: rgb(10, 30, 94);
				height: 30px;
				width: 30px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				vertical-align: center;
				i {
					line-height: 30px;
				}
				&:hover {
					background-color: #f7711c;
					cursor: pointer;
				}
			}
		}
	}
	.bottomLine {
		background-color: #6175c1;
		opacity: 0.3;
		height: 1px;
		width: 100%;
		margin: 15px 0;
	}

	.copyrightContainer {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		color: #acb8d9;

		.copyright {
			color: inherit;
		}
		.createdBy {
			color: inherit;

			a {
				color: inherit;
				transition: 0.3s ease-in-out;
				&:hover {
					color: #f7711c;
					cursor: pointer;
				}
			}
		}
		@media (max-width: 991px) {
			font-size: 13px;
		}
		@media (max-width: 480px) {
			font-size: 0.8rem;

			.copyright {
				margin: 0;
			}
		}
	}
	.Language {
		display: flex;
		justify-content: flex-end !important;
	}

	.infoLanguage {
		margin-bottom: 15px;
		.language {
			font-size: 17px;
			vertical-align: middle;
			color: #828ba9;
			display: inline-block;
			&:hover {
				color: #f7711c;
				cursor: pointer;
			}
		}
	}
}

@media (max-width: 768px) {
	.Footer {
		.menu {
			display: flex;
			flex-direction: column-reverse;

			.languageLine {
				background-color: #6175c1;
				opacity: 0.3;
				height: 1px;
				width: 100%;
				margin: 15px 0;
			}
		}
	}
}

@media (max-width: 576px) {
	.Footer {
		.footerTitle {
			margin-top: 1.5rem;
		}
		.menu {
			display: flex;
			flex-direction: column-reverse;
			.languageLine {
				background-color: #6175c1;
				opacity: 0.3;
				height: 1px;
				width: 100%;
				margin: 15px 0;
			}
			.infoPart {
				justify-content: space-evenly;
			}
			.Language {
				width: 100%;
				justify-content: space-evenly !important;
			}
			.infoIcon {
				margin-top: 1rem;
				margin-bottom: 0.3rem;
			}
		}
	}
}

@media (max-width: 426px) {
	.Footer {
		.menu {
			.infoPart {
				.link {
					font-size: 1rem;
				}
			}
		}
		.infoLanguage {
			justify-content: space-evenly !important;
		}
	}
}
